import React from "react"
import { Row, Button, Col, Container } from "react-bootstrap"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"
import style from "./style.module.scss"

export default function(pathConsulting) {
  return (
    <Container className="text-center">
      <Row className="py-5">
        <Col>
          {pathConsulting.pathConsulting === false ? (
            <React.Fragment>
              <h2>
                <FormattedHTMLMessage id="contactSectionHeader" />
              </h2>
              <p>
                <FormattedMessage id="contactSectionText" />
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h2>
                <FormattedMessage id="consultingContactUsSectionHeader" />
              </h2>
            </React.Fragment>
          )}
          <Button
            className={style.blueGradientButton}
            onClick={() => {
              window.$crisp && window.$crisp.push(["do", "chat:open"])
            }}
          >
            {pathConsulting.pathConsulting === false ? (
              <FormattedMessage id="contactSectionButton" />
            ) : (
              <FormattedMessage id="consultingContactUsSectionButton" />
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
