import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import ContactUsSection from "components/ContactUsSection/index"
import OurPartnersSection from "components/OurPartnersSection/CustomerLogos"
import { graphql} from "gatsby"

// Import icons
import style from "./style.module.scss"
import svgCloud from "styles/HomeIcons/cloud.svg"
import svgQuallity from "styles/HomeIcons/quallity.svg"
import svgRealTime from "styles/HomeIcons/realTime.svg"
import svgSetup from "styles/HomeIcons/setup.svg"
import svgStandards from "styles/HomeIcons/standards.svg"

import Slider from "components/Slider/Image"

import Layout from "components/Layout/en"

import video from "./bg.mp4"

class Home extends React.Component {
  render() {
    const header = {
      x: 38,
      y: 56,
      text: "Quality matters.",
      description:
        "Deep Learning based visual inspection for difficult quality assurance tasks.",
      image: video,
      big: true,
    }
    return (
      <Layout header={header} location={this.props.location}>
        <section className="grey-background">
          <Container>
            <Row className="pt-5 row-eq-height">
              <Col>
                <h3>
                  With the intelligent visual recognition of Moonvision we
                  automate your quality control in the
                </h3>
                <ul>
                  <li className={style.list_style}>Assembly line</li>
                  <li className={style.list_style}>Metal processing</li>
                  <li className={style.list_style}>Welding</li>
                  <li className={style.list_style}>Wood Processing</li>
                  <li className={style.list_style}>Paint Shop</li>
                </ul>
              </Col>
              <Col md="6" className={style.benefits}>
                <h3>You benefit from:</h3>
                <Row>
                  <Col md="6">
                    <img
                      src={svgRealTime}
                      width={60}
                      alt="illustration realtime"
                    />
                    <h4>Recognition in Realtime</h4>
                  </Col>
                  <Col md="6">
                    <img src={svgCloud} width={60} alt="illustration cloud" />
                    <h4 style={{ fontWeight: "bold" }}>
                      Application both in the cloud and fail-proof on the spot
                    </h4>
                  </Col>
                  <Col md="6">
                    <img src={svgSetup} width={60} alt="illustration setup" />
                    <h4>Reduced setup time</h4>
                  </Col>
                  <Col md="6">
                    <img
                      src={svgQuallity}
                      width={60}
                      alt="illustration quality"
                    />
                    <h4>Reliable and consistent high quality</h4>
                  </Col>
                  <Col md={{ span: 6, offset: 3 }}>
                    <img
                      src={svgStandards}
                      width={60}
                      alt="illustration standards"
                    />
                    <h4>Compliance with industry standards</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <Container>
          <Row className="pt-5">
            <Col className="text-md-center text-left">
              <h3>Always one step ahead</h3>
            </Col>
          </Row>
          <Row>
            <Col md="8" sm="6">
              <div>
                <p>
                  We would be pleased to advise you comprehensively with regard
                  to your specific application case and show you how you can
                  increase efficiency.
                </p>
                <p>
                  We have extensive project experience in the following areas
                  especially in the automotive industry. Thanks to our hardware
                  partner you can use our software at the support a wide variety
                  of tasks. Our industry-leading recognition software helps you
                  to to stay one step ahead of your competitors.
                </p>
              </div>
              <ul style={{ marginTop: "3rem", marginLeft: "-3rem" }}>
                <li className={style.list_style} style={{ fontWeight: "bold" }}>
                  Toolbox for data management and labelling
                </li>
                <li className={style.list_style} style={{ fontWeight: "bold" }}>
                  Self-learning "Deep Learning" algorithms
                </li>
                <li className={style.list_style} style={{ fontWeight: "bold" }}>
                  High detection rates
                </li>
                <li className={style.list_style} style={{ fontWeight: "bold" }}>
                  Detailed reporting
                </li>
                <li className={style.list_style} style={{ fontWeight: "bold" }}>
                  Maintain control over the important quality assurance process
                </li>
              </ul>
            </Col>
            <Col md="4" sm="6" className="pb-3">
              <Slider />
            </Col>
          </Row>
        </Container>
        <ContactUsSection pathConsulting={false} />
        <OurPartnersSection />
      </Layout>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
