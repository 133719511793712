import React from "react"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/scss/swiper.scss"
import Image from "components/Image/index"

import woodInspectionImg from "pages/products/woodscanner/wood_inspection_0.jpg"
import rustInspectionImg from "pages/products/metalscanner/rust_1.jpg"

export default function() {
  const params = {
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    centeredSlides: true,
  }
  return (
    <Swiper {...params}>
      <div>
        <Image filename={woodInspectionImg} alt="wood inspection" />
      </div>
      <div>
        <Image filename={rustInspectionImg} alt="rust inspection" />
      </div>
    </Swiper>
  )
}
